var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row",attrs:{"id":"expert"}},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row align-items-center mb-4"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"all-tabs"},[_c('div',{staticClass:"mx-2 opt",class:_vm.activeTab == 'all' ? ' tab-options' : '',on:{"click":function($event){$event.preventDefault();_vm.fetchData('all');
                    _vm.currentPage = 1;}}},[_vm._v(" All ")]),_vm._v(" | "),_c('div',{staticClass:"mx-2 opt",class:_vm.activeTab == 'trash' ? ' tab-options' : '',on:{"click":function($event){$event.preventDefault();_vm.fetchData('trash');
                    _vm.currentPage = 1;}}},[_vm._v(" Trash ")]),_vm._v(" | "),_c('div',{staticClass:"mx-2 opt",class:_vm.activeTab == 'active' ? ' tab-options' : '',on:{"click":function($event){$event.preventDefault();_vm.fetchData('active');
                    _vm.currentPage = 1;}}},[_vm._v(" Active ")]),(_vm.can('add-tax'))?[_c('div',{staticClass:"d-flex align-items-center"},[_c('svg',{staticClass:"svg-inline--fa fa-table mx-2",attrs:{"height":"16px","width":"16px","aria-hidden":"true","focusable":"false","data-prefix":"fa","data-icon":"table","role":"img","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512","data-fa-i2svg":""}},[_c('path',{attrs:{"fill":"currentColor","d":"M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224 160H288v-96h160v96zm0-160H288v-96h160v96z"}})]),_c('router-link',{staticClass:"podcast-options",attrs:{"to":{ name: 'add-tax' }}},[_vm._v("Add Tax")])],1)]:_vm._e()],2)])]),_c('div',{staticClass:"table-responsive mb-0"},[_c('b-table',{key:_vm.key,staticClass:"table-bordered table-hover",attrs:{"striped":"","bordered":"","items":_vm.tableData.data,"per-page":0,"current-page":_vm.currentPage,"fields":_vm.fields,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"filter-included-fields":_vm.filterOn,"responsive":"sm"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(is_active)",fn:function(row){return [_c('b-form-checkbox',{staticClass:"mb-1",attrs:{"switch":"","checked":row.item.is_active == 1 ? true : false},on:{"change":function($event){return _vm.updateStatus(row.item.id)}}})]}},{key:"cell(edit)",fn:function(row){return [_c('router-link',{attrs:{"to":{
                    name: 'edit-tax',
                    params: { id: row.item.id },
                  }}},[_c('i',{staticClass:"fa fa-edit edit-i"})])]}},(_vm.fields[_vm.fields.length - 1].key == 'delete')?{key:"cell(delete)",fn:function(row){return [(_vm.activeTab != 'trash')?_c('div',{on:{"click":function($event){$event.preventDefault();return _vm.deleteTax(row.item.id)}}},[_c('i',{staticClass:"mdi mdi-delete delete-i"})]):_vm._e()]}}:null],null,true)})],1),(_vm.tableData.total_pages > 1)?[_c('div',{staticClass:"data_paginate"},[_c('div',{staticClass:"dataTables_paginate paging_simple_numbers d-flex justify-content-end"},[_c('ul',{staticClass:"pagination pagination-rounded mb-0"},[_c('b-pagination',{attrs:{"first-number":"","last-number":"","total-rows":_vm.tableData.total,"total-pages":_vm.tableData.total_pages,"per-page":_vm.tableData.per_page},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)]),_c('div',{staticClass:"form-inline navbar-search"},[_c('span',{staticClass:"mr-2"},[_vm._v("Go to a Particular Paginated Page :")]),_c('div',{staticClass:"input-group"},[_c('b-form-input',{staticClass:"in-width",attrs:{"id":"case","placeholder":"Enter Page No"},model:{value:(_vm.pageNo),callback:function ($$v) {_vm.pageNo=$$v},expression:"pageNo"}}),_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-search",on:{"click":_vm.filterPage}},[_c('i',{staticClass:"fas fa-search fa-sm"})])])],1)])])]:_vm._e()],2)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }